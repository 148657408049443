<template>
<b-card-code title="Users List" no-body>

    <!-- <list-users :key="index" v-for="(user, index) in userList" :user="userList" :id="index"></list-users> -->
    <div>
        <b-table :items="userList" id="pages-table" :primary-key="id" :fields="fields" striped responsive class="mb-0">
            <template #cell(actions)="row">
              
                <b-button-group class="my-1">
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon" >
                        {{ row.item.id }}
                        <feather-icon icon="EditIcon" />
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon">
                        <feather-icon icon="ListIcon" />
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon">
                        <feather-icon icon="ArchiveIcon" />
                    </b-button>
                </b-button-group>
            </template>

            <template #cell(profile_photo)="data">
                <b-avatar :src="data.value" />
            </template>

            <template #cell(status)="data">
                <b-badge :variant="status[1][data.value]">
                    {{ status[0][data.value] }}
                </b-badge>
            </template>
        </b-table>
    </div>

</b-card-code>
</template>

<script>
import {
    mapGetters
} from "vuex";
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import ListUsers from "../components/ListUsers.vue";

import {
    BTable,
    BFormCheckbox,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButtonGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {

    components: {
        BCardCode,
        ListUsers,
        BButtonGroup,
        BTable,
        BButton,
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BBadge,
        BAvatar,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            fields: ['id', {
                key: 'profile_photo',
                label: 'Avatar'
            }, 'name', 'email', {
                key: 'status',
                label: 'Status'
            }, 'actions'],
            /* eslint-disable global-require */
            status: [{
                    1: 'Online',
                    2: 'Offline',
                    3: 'Banned',
                    4: 'On vacation',
                    5: 'Applied',
                },
                {
                    1: 'light-success',
                    2: 'light-danger',
                    3: 'light-warning',
                    4: 'light-primary',
                    5: 'light-info',
                }
            ],
        }
    },
    computed: {
        ...mapGetters(["userList"]),
    },

    mounted() {
        window.Echo.channel("addUser").listen(".user-created", (users) => {
            this.$store.commit("ADD_USER", users);
    });

        this.$store.dispatch("GET_USERS");
    },
}
</script>

<style lang="css">
.icon-space {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
</style>
