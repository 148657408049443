<template>
<div>
    <b-table :items="items" :fields="fields" striped responsive class="mb-0">
        <template #cell(actions)="row">
            <b-button-group class="my-1">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="btn-icon">
                    <feather-icon icon="EditIcon" />
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="btn-icon">
                    <feather-icon icon="ListIcon" />
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="btn-icon">
                    <feather-icon icon="ArchiveIcon" />
                </b-button>
            </b-button-group>
        </template>

        <!-- full detail on click -->
        <template #row-details="row">
            <b-card>
                <b-row class="mb-2">
                    <b-col md="4" class="mb-1">
                        <strong>Full Name : </strong>{{ row.item.full_name }}
                    </b-col>
                    <b-col md="4" class="mb-1">

                        <strong>Email : </strong>{{ row.item.email }}
                    </b-col>
                    <b-col md="4" class="mb-1">

                    </b-col>
                </b-row>

                <b-button size="sm" variant="outline-secondary" @click="row.toggleDetails">
                    Hide Details
                </b-button>
            </b-card>
        </template>

        <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
        </template>

        <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
            </b-badge>
        </template>
    </b-table>
</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
    BTable,
    BFormCheckbox,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BButtonGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BButtonGroup,
        BCardCode,
        BTable,
        BButton,
        BFormCheckbox,
        BCard,
        BRow,
        BCol,
        BBadge,
        BAvatar,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            fields: ['id', {
                key: 'avatar',
                label: 'Avatar'
            }, 'full_name', 'email', {
                key: 'status',
                label: 'Status'
            }, 'actions'],
            /* eslint-disable global-require */
            items: [{
                id: 1,
                avatar: require('@/assets/images/avatars/10-small.png'),
                full_name: "Korrie O'Crevy",
                email: 'kocrevy0@thetimes.co.uk',
                status: 2,
            }, ],
            /* eslint-disable global-require */
            status: [{
                    1: 'Online',
                    2: 'Offline',
                    3: 'Banned',
                    4: 'On vacation',
                    5: 'Applied',
                },
                {
                    1: 'light-success',
                    2: 'light-danger',
                    3: 'light-warning',
                    4: 'light-primary',
                    5: 'light-info',
                }
            ],
        }
    },
}
</script>

<style lang="css">
.icon-space {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
}
</style>
